import React from 'react';
import Logo from "../Common/Logo";
import HamburgerMenuIcon from "../Common/HamburgerMenuIcon";
import Menu from "./Menu";
import RegisterNowButton from "../Common/RegisterNowButton";

function Header() {

    const [scrolling, setScrolling] = React.useState(false);

    const changeBackground = () => {
        if (window.scrollY > 100) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    }

    window.addEventListener("scroll", changeBackground);

    return (
        <nav className={`flex z-10  px-4 py-2 flex-row items-center justify-between
                        md:py-5 md:px-6 fixed top-0 w-full ` + (scrolling ? " bg-white/70 backdrop-blur" : " bg-transparent ") }>
            <div className="flex flex-row place-items-center md:space-x-10">
                <Logo/>
                <Menu/>
            </div>
            <RegisterNowButton showOnlyDesktop={true}/>
            <HamburgerMenuIcon/>
        </nav>
    );
}

export default Header;