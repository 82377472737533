import React from 'react';
import {scrollTo} from "../../Functions/scrollTo";

function Menu() {
    return (
        <ul className="hidden md:flex flex-row space-x-10">
            <li onClick={()=>scrollTo("landing")}> Start</li>
            <li onClick={()=>scrollTo("services")}>  Services</li>
            <li onClick={()=>scrollTo("about-us")}> About</li>
            <li onClick={()=>scrollTo("price-list")}> Stocklist</li>
            <li onClick={()=>scrollTo("contact-us")}> Contact</li>
        </ul>
    );
}

export default Menu;