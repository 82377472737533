import React from 'react';
import Questions from "./Questions";

function Faq() {
    return (
        <div className="bg-[#F2F2F2]">
            <div className="grid lg:grid-cols-2 py-20">
                <div className="px-10 lg:px-36 flex flex-col justify-center items-center space-y-12">
                    <div className="flex flex-row place-items-center self-start  space-x-4 ">
                        <svg width="30" height="11" viewBox="0 0 30 11" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <rect x="0.890625" y="0.5" width="29" height="10" fill="url(#pattern0)"/>
                            <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                    <use xlinkHref="#image0_2597_4346" transform="scale(0.0344828 0.1)"/>
                                </pattern>
                                <image id="image0_2597_4346" width="29" height="10" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAKCAYAAABIQFUsAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIxJREFUeNpiYEAAXyDeB8Sfgfg/FfFnqLk+DGigBYhvAXEQEPMxUBfwQc29BbUHDEAuuAnEAgy0BSDzb0BDFOz1IAb6gCCofQyfaBCkuAAvyD4mBvoCUMJiZAYSrkD8Boiv08FSUPqRgWWVG/ROSOhZhpcG8RgEzSEt6JJ+0JT1hcqFwxeouXAfAgQYAMGaQTIUjNzsAAAAAElFTkSuQmCC"/>
                            </defs>
                        </svg>
                        <span className="uppercase text-red tracking-widest">CONTACT</span>
                    </div>
                    <p className="text-xl lg:text-3xl font-bold leading-relaxed tracking-wide text-justify">
                        You can find the FAQ here however, if you need any further information and assistance, we are more
                        than happy to help you out.</p>
                </div>
                <img className="bg-white rounded-full p-5 w-2/3 lg:w-auto mt-10 md:w-1/2 lg:mt-0 mx-auto" src="/image%202.png" alt=""/>
            </div>
            <h2 className="text-center text-3xl font-bold">Frequently Asked Questions</h2>

            <Questions/>

        </div>
    );
}

export default Faq;