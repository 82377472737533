import React from 'react';

function Banner() {
    return (
        <div id="landing" className="grid md:grid-cols-2 flex place-items-center justify-items-start pt-24 md:pt-14 md:mt-0  bg-[#F2F2F2]">
            <div className="lg:pl-16 mx-auto relative">
                <svg className="absolute lg:translate-x-52 translate-x-32 -top-24 w-16 lg:w-40" width="213" height="213" viewBox="0 0 213 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="212.047" height="212.047" rx="106.023" fill="#DD1919"/>
                </svg>

                <h1 className="lg:text-6xl text-4xl  font-bold leading-[3rem]  lg:leading-[4.5rem] z-1 relative">
                    Wholesale<br className=" lg:block"/> and retail
                    <br/> solutions for<br className=" lg:block"/> business</h1>
                <div className="col-span-1 hidden lg:grid w-3/4 grid grid-cols-4 lg:-space-x-10 mt-8">
                    <a rel="noreferrer" target="_blank" href="tel:+31 6 87 07 48 42">
                        <img className="w-12 hover:animate-pulse delay-75 cursor-pointer" src="/phone.png" alt=""/>
                    </a>
                    <a rel="noreferrer" target="_blank" href="mailto:contact.wholesalepoint@gmail.com">
                        <img className="w-12 hover:animate-pulse delay-75 cursor-pointer" src="/email.png" alt=""/>
                    </a>
                    <div className="col-span-2">
                        Feel free to contact us anytime
                        Mon-  Fri between 09:00 - 17:00
                    </div>
                </div>
            </div>
            <img className="w-2/3 mx-auto md:w-full " src="/banner%201.png" alt=""/>

        </div>
    );
}

export default Banner;