import React from 'react';

function AboutUs() {
    return (
        <div id="about-us" className="bg-[#F2F2F2] -mt-16 lg:-mt-3 pt-20 ">
            <h2 className="text-center pb-20 pt-5 lg:pt-0 text-red tracking-widest">ABOUT US</h2>
            <p className="font-medium md:px-20 px-10 lg:px-72 lg:text-[20px] tracking-wider leading-relaxed">For years, we have developed a network across Europe and a strong product database.
                Now we put our experience and knowledge at the disposal of our customers.
                We are dedicated to maximize our efficiency as our priority is providing best quality of products and services.
                We are doing our best to provide best wholesale experience to our customers. Fast and safe logistics are offered to our customer.
                Also our customer service is available for our customers to have best experience possible.</p>
            <div className="grid md:grid-cols-2 gap-10 lg:grid-cols-4 mt-20 px-10 md:px-20 lg:px-40">
                <div className="flex flex-col space-y-7 items-center justify-center">
                    <h2 className="font-bold text-2xl">Fragrance</h2>
                    <span>+ 1.000 Brands</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.16953 15.34C6.12953 15.34 4.38953 14.64 2.94953 13.24C1.54953 11.8 0.849532 10.06 0.849532 8.02C0.849532 5.94 1.54953 4.2 2.94953 2.8C4.38953 1.36 6.12953 0.639998 8.16953 0.639998C10.2495 0.639998 12.0095 1.36 13.4495 2.8C14.8895 4.2 15.6095 5.94 15.6095 8.02C15.6095 10.06 14.8895 11.8 13.4495 13.24C12.0095 14.64 10.2495 15.34 8.16953 15.34Z" fill="#D00909"/>
                    </svg>
                    <p className="pt-4 md:pt-8 text-center leading-relaxed">The Best Fragrances for<br/> Everyone : Wholesale <br/>products on Budget</p>
                </div>
                <div className="flex flex-col space-y-7 items-center justify-center">
                    <h2 className="font-bold text-2xl">Health & Beauty</h2>
                    <span>+ 60.000 items</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.16953 15.34C6.12953 15.34 4.38953 14.64 2.94953 13.24C1.54953 11.8 0.849532 10.06 0.849532 8.02C0.849532 5.94 1.54953 4.2 2.94953 2.8C4.38953 1.36 6.12953 0.639998 8.16953 0.639998C10.2495 0.639998 12.0095 1.36 13.4495 2.8C14.8895 4.2 15.6095 5.94 15.6095 8.02C15.6095 10.06 14.8895 11.8 13.4495 13.24C12.0095 14.64 10.2495 15.34 8.16953 15.34Z" fill="#D00909"/>
                    </svg>
                    <p className="pt-4 md:pt-8 text-center leading-relaxed">Beauty on a Budget:<br/> Best Deals and Sales for Beauty<br/> Products Online</p>
                </div>
                <div className="flex flex-col space-y-7 items-center justify-center">
                    <h2 className="font-bold text-2xl">Cosmetics</h2>
                    <span>Quality product</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.16953 15.34C6.12953 15.34 4.38953 14.64 2.94953 13.24C1.54953 11.8 0.849532 10.06 0.849532 8.02C0.849532 5.94 1.54953 4.2 2.94953 2.8C4.38953 1.36 6.12953 0.639998 8.16953 0.639998C10.2495 0.639998 12.0095 1.36 13.4495 2.8C14.8895 4.2 15.6095 5.94 15.6095 8.02C15.6095 10.06 14.8895 11.8 13.4495 13.24C12.0095 14.64 10.2495 15.34 8.16953 15.34Z" fill="#D00909"/>
                    </svg>
                    <p className="pt-4 md:pt-8 text-center leading-relaxed">Makeup for Everyone:<br/> Makeup Products with Best <br/>Wholesale Prices</p>
                </div>
                <div className="flex flex-col space-y-7 items-center justify-center">
                    <h2 className="font-bold text-2xl">Price Advantages</h2>
                    <span>Consultant</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.16953 15.34C6.12953 15.34 4.38953 14.64 2.94953 13.24C1.54953 11.8 0.849532 10.06 0.849532 8.02C0.849532 5.94 1.54953 4.2 2.94953 2.8C4.38953 1.36 6.12953 0.639998 8.16953 0.639998C10.2495 0.639998 12.0095 1.36 13.4495 2.8C14.8895 4.2 15.6095 5.94 15.6095 8.02C15.6095 10.06 14.8895 11.8 13.4495 13.24C12.0095 14.64 10.2495 15.34 8.16953 15.34Z" fill="#D00909"/>
                    </svg>
                    <p className="pt-4 md:pt-8 text-center leading-relaxed">Thanks to our system we<br/> compare all the offers and<br/> offer you the best ones.</p>
                </div>
            </div>
            <img src="/about%20us%201.png" className="mx-auto w-1/2 md:w-1/3" alt=""/>
        </div>
    );
}

export default AboutUs;