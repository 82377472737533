import React from 'react';
import {scrollTo} from "../../Functions/scrollTo";

function RegisterNowButton({extraCSS, showOnlyMobile, showOnlyDesktop, text}) {
    return (
        <button onClick={()=>scrollTo("contact-us")} className={`bg-black text-white py-2.5 w-40 mx-4 mb-4 mt-2 
                            rounded-[10px] font-bold text-sm `
                            + (showOnlyMobile ? " md:hidden " : " ")
                            + (showOnlyDesktop ? " hidden md:block " : " ")
                            + (extraCSS ? extraCSS : null )}>
            {text ? text : "Register Now"}
        </button>
    );
}

export default RegisterNowButton;