import Header from "./Components/Header/Header";
import WhyShouldChooseUs from "./Components/WhyShouldChooseUs";
import AboutUs from "./Components/AboutUs";
import Categories from "./Components/Categories";
import Faq from "./Components/FAQ/Faq";
import ContactForm from "./Components/ContactForm";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import {SideBar} from "./Components/Common/SideBar";
import PriceList from "./Components/PriceList";

function App() {
  return (
    <div className="w-max-screen overflow-hidden">
        <SideBar/>
      <Header/>
      <Banner/>
      <WhyShouldChooseUs/>
      <AboutUs/>
      <PriceList/>
      <Categories/>
      <Faq/>
      <ContactForm/>
      <Footer/>
    </div>
  );
}

export default App;
