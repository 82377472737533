import React, { Component } from 'react';
import Faq from 'react-faq-component';

const data = {
    rows: [
        {
            title: "How can we place an order?",
            content: "You must register on our website to receive our product catalog, " +
                "then by sending us your required products we will create an order for you."
        },
        {
            title: "What are your payment options?",
            content: "We offer the bank transfer as payment method. Please be aware that we only work pre-paid, once we get your payment, your order will be processed immediately."
        },
        {
            title: "What are your shipping options?",
            content: "Depending on the country, we use DPD, DHL, UPS to dispatch your order."
        },
        {
            title: "What is your return policy ?",
            content: "We would like to inform you that except exceptional circumstances, we do not accept returns."
        },
        ]
}

const data_2 = {
    rows : [
            {
                title: "How long is your process time and shipping time?",
                content:"Our process time is 4-6 business days, of course, this time doesn’t include holidays etc. Shipping time depends on the shipping country but our courier services has been selected carefully to ensure safe and fast delivery."
            },
            {
                title: "Which countries do you ship to?",
                content: "We can ship your orders in all over Europe."
            },
            {
                title: "Is your product feed regularly updated?",
                content: "Our product feed is very regularly updated. Our systems updates all our offers every hour."
            },
            {
                title: "Can I cancel my order?",
                content : "Once the process of your order has started, we will no longer be able to cancel your order."
            }]
}

const styles = {
    bgColor: '#F2F2F2',
    titleTextColor: "black",
    rowTitleTextSize: 'small',
    rowContentPaddingTop: '20px',
    rowContentPaddingBottom: '20px',
    rowContentPaddingLeft: '50px',
    rowContentTextSize: '15px',
    rowTitleColor: "#444444",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
     animate: true,
     arrowIcon: " ",
     tabFocus: true,
};

export default function Questions() {

    return (
        <div className="grid lg:grid-cols-2 lg:px-60 pt-20 lg:gap-20">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />

            <Faq
                data={data_2}
                styles={styles}
                config={config}
            />

        </div>
    );
}