import React from 'react';
import {RxHamburgerMenu} from "react-icons/rx";
import {useProSidebar} from "react-pro-sidebar";
function HamburgerMenuIcon() {
    const { toggleSidebar } = useProSidebar();
    return (
        <RxHamburgerMenu onClick={() => toggleSidebar()} className="w-7 h-7 md:hidden"/>
    );
}

export default HamburgerMenuIcon;