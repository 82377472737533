import React from 'react';
import RegisterNowButton from "./Common/RegisterNowButton";

function WhyShouldChooseUs() {
    return (
        <>
            <div className="grid lg:grid-cols-2 justify-items-center py-12 space-y-20 lg:space-y-0 lg:py-24">
            <div className="space-y-5">
                <div className="flex flex-row place-items-center space-x-4 ">
                    <svg width="30" height="11" viewBox="0 0 30 11" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <rect x="0.890625" y="0.5" width="29" height="10" fill="url(#pattern0)"/>
                        <defs>
                            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlinkHref="#image0_2597_4346" transform="scale(0.0344828 0.1)"/>
                            </pattern>
                            <image id="image0_2597_4346" width="29" height="10" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAKCAYAAABIQFUsAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIxJREFUeNpiYEAAXyDeB8Sfgfg/FfFnqLk+DGigBYhvAXEQEPMxUBfwQc29BbUHDEAuuAnEAgy0BSDzb0BDFOz1IAb6gCCofQyfaBCkuAAvyD4mBvoCUMJiZAYSrkD8Boiv08FSUPqRgWWVG/ROSOhZhpcG8RgEzSEt6JJ+0JT1hcqFwxeouXAfAgQYAMGaQTIUjNzsAAAAAElFTkSuQmCC"/>
                        </defs>
                    </svg>
                    <span className="uppercase text-red">Why should you choose us?</span>
                </div>
                <h2 className="text-xl md:text-2xl font-bold">
                    We are committed to <br/> providing a diverse <br/>
                    selection of items <br/> from a variety of <br/> brands & categories.
                </h2>
                <RegisterNowButton  extraCSS="-ml-0 w-32" text={"Contact Us"}/>
            </div>
            <div className="flex lg:justify-self-start flex-col space-y-5 text-lg px-10 md:text-xl font-medium">
                <div className="flex space-x-5 flex-row place-items-center">
                    <img className="h-20" src="/consultant2-pic1.png.png" alt=""/>
                    <span>Experience The Wide Range Of <br className="hidden md:block"/>Products with The Best Quality</span>
                </div>
                <div className="flex space-x-5 flex-row place-items-center">
                    <img className="h-20" src="/consultant2-pic2.png.png" alt=""/>
                    <span> Every Hour Updated Feed</span>
                </div>
                <div className="flex space-x-5 flex-row place-items-center">
                    <img className="h-20" src="/consultant2-pic2.png.png" alt=""/>
                    <span>Exceptional Customer Service</span>
                </div>
            </div>
        </div>
            <img className="mx-auto" src="/consultant2-pic4.png.png" alt=""/>
        </>
    );
}

export default WhyShouldChooseUs;