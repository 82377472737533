import React from 'react';

function Categories() {
    return (
        <div id="services" className="grid lg:grid-cols-2 pl-5 lg:px-44 py-20 gap-y-20 lg:gap-y-32">
            <div className="space-y-12">
                <div className="flex flex-row place-items-center space-x-4">
                    <img src="/consultant2-icon4.png.png" alt=""/>
                    <h2 className="font-bold text-2xl">Fragrance</h2>
                </div>
                <p className="leading-relaxed px-10 lg:px-20 text-justify">Fragrance range in our website comprises high-end designer names as well as unique and niche products , giving our clients access
                    to the current trends as well as classics. We provide perfumes for men and women, as well as unisex smells suitable for any occasion.</p>
            </div>
            <div className="space-y-12">
                <div className="flex flex-row place-items-center space-x-4">
                    <img src="/consultant2-icon4.png.png" alt=""/>
                    <h2 className="font-bold text-2xl">Original Brands</h2>
                </div>
                <p className="leading-relaxed px-10 lg:px-20 text-justify">
                    All of our fragrance products are authentic and guaranteed to be of the highest quality.
                    We work with trusted suppliers and distributors to ensure that our clients receive only the best products.
                </p>
            </div>
            <div className="space-y-12">
                <div className="flex flex-row place-items-center space-x-4">
                    <img src="/consultant2-icon4.png.png" alt=""/>
                    <h2 className="font-bold text-2xl">Health & Beauty & Cosmetics</h2>
                </div>
                <p className="leading-relaxed px-10 lg:px-20 text-justify">
                    We maintain that everyone should have easy access to beauty. For this reason, we provide a wide selection of products
                    to suit all skin types and preferences.
                    We have you covered whether you want a simple, daily appearance or a bold, extravagant style for a special occasion.
                </p>
            </div>
            <div className="space-y-12">
                <div className="flex flex-row place-items-center space-x-4">
                    <img src="/consultant2-icon4.png.png" alt=""/>
                    <h2 className="font-bold text-2xl">Makeup</h2>
                </div>
                <p className="leading-relaxed px-10 lg:px-20 text-justify">
                    The base of every beautiful makeup look is healthy skin. We thus provide a variety of skincare items that address various skin issues and kinds.
                    Our skincare products are formulated with soothing, all-natural components that calm, moisturise, and protect your skin.
                </p>
            </div>
        </div>
    );
}

export default Categories;