import React from 'react';
import axios from "axios";

function ContactForm() {

    const [response, setResponse] = React.useState(null);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const name = "Name : " + e.target.name.value;
        const surname = "Surname : " + e.target.surname.value;
        const phone = "Phone : " + e.target.phone.value;
        const info ="<br><br>" + name + "<br>" + surname + "<br>" + phone;
        const formValues = {
            to: e.target.email.value,
            subject: e.target.subject.value,
            message: e.target.message.value + info
        }
        axios.post("https://wholesalepoint.nl/backend/mail.php", formValues, {headers : {"Content-Type" : "application/json"}}).then((response) => {
            console.log(response)
            setResponse(response.data.data);
        }).catch((error) => {
            console.log(error)
            setResponse(error.response.data.error);
        });
    }

    return (
        <div id="contact-us" className="contact-form py-20 bg-[#F2F2F2]">
            <h2 className="text-center text-3xl font-bold pb-10">Write us a message</h2>
            <form onSubmit={handleFormSubmit} className="flex flex-col space-y-4 place-items-center px-10 lg:px-60">
                <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5 w-full">
                    <input className="bigger lg:normal" type="text" name="name" id="name" placeholder="Name" required/>
                    <input className="bigger lg:normal" type="text" name="surname" id="surname" placeholder="Surname" required/>
                </div>
                <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5 w-full">
                    <input className="bigger lg:big" type="email" name="email" id="email" placeholder="Email" required/>
                    <input className="bigger lg:small" type="tel" name="phone" id="phone" placeholder="Phone" required/>
                </div>
                <input className="bigger" type="text" name="subject" id="subject" placeholder="Subject" required/>
                <textarea rows={8} name="message" id="message" placeholder="Message" required/>
                <button type="submit" className="bg-red text-white w-full py-2 rounded-lg">Send a Message</button>
            </form>
            {
                response ? <div className="text-center text-2xl font-bold">{response}</div> : null
            }
        </div>
    );
}

export default ContactForm;