import React from 'react';
import axios from "axios";

function PriceList() {
    const [response, setResponse] = React.useState(null);

    const handleFormSubmit = (e) => {

        e.preventDefault();
        const to = "Email : " + e.target.email.value;
        const formValues = {
            to: "contact.wholesalepoint@gmail.com",
            subject: "Wholesalepoint.nl - Pricelist Request",
            message: "A customer stated that he wanted a pricelist. Please send him the pricelist. <br><br>" + to
        }
        axios.post("https://wholesalepoint.nl/backend/mail.php", formValues, {headers : {"Content-Type" : "application/json"}}).then((response) => {
            console.log(response)
            setResponse(response.data.data);
        }).catch((error) => {
            console.log(error)
            setResponse(error.response.data.error);
        });
    }

    return (
        <div id="price-list" className="grid px-12 lg:grid-cols-2 lg:mx-[4vw] py-20 gap-y-5 lg:gap-y-14 border-b-2 border-black ">
            <h1 className="font-bold text-3xl">Save On Wholesale Beauty Supplies</h1>
            <span></span>
            <p className="text-justify leading-loose">
                Our vision is to be a one-stop shop for all of our clients' Beauty & Health needs, from luxury
                skincare and cosmetics goods to budget everyday essential as a wholesale. We provide a diverse
                choice of items from both recognized and affordable brands, ensuring that our clients have access
                to the industry's most recent trends.
                <br/><br/>
                Are you looking for the best prices and wide range of products?
                You are at the right place. We acknowledge the importance of maintaining great connections with our clients, and we aim to fulfil their expectations in every engagement. From product suggestions to order tracking and delivery, our qualified and helpful employees is always accessible to give help and support.
                We appreciate the belief our clients have put in us, and we are dedicated to maintaining our high standards of quality.
            </p>
            <div className="lg:p-16 p-10 border border-black rounded-[40px] lg:rounded-[80px] mt-10 lg:mx-20 lg:py-24 lg:-mt-16 space-y-16 text-center">
                <h2 className="text-xl font-bold tracking-wide">Subscribe to our pricelist</h2>
                <p>Register your email address to stay informed of our latest offers and receive our updated stocklist</p>
                <form onSubmit={handleFormSubmit} className="space-y-4 lg:mx-3">
                    <input name="email" placeholder="Email address" type="email" className="p-3 font-medium w-full text-center border rounded-xl placeholder:text-black border-black" required/>
                    <button type="submit" className="bg-black text-white w-full py-3 rounded-xl">Subscribe</button>
                </form>
                {
                    response ? <div className="text-center text-2xl font-bold">{response}</div> : null
                }
            </div>
        </div>
    );
}

export default PriceList;