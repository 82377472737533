import {Sidebar, Menu, MenuItem, useProSidebar} from 'react-pro-sidebar';
import {TfiClose} from "react-icons/tfi";
import RegisterNowButton from "./RegisterNowButton";
import {scrollTo} from "../../Functions/scrollTo";

export function SideBar() {
    const { toggleSidebar } = useProSidebar();
    return (
        <div  className="flex justify-end absolute right-0 text-sm" >
            <Sidebar backgroundColor="#EEEDED" breakPoint="always"  rtl={true}>
                <TfiClose className="mr-4 mt-5 mb-4 w-5 h-5" onClick={()=>toggleSidebar()}/>
                <RegisterNowButton extraCSS={"w-[86%]"} showOnlyMobile={true}/>
                <Menu  menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                            return {
                                textAlign: "left",
                                height: "45px",
                                borderBottom: "1px solid #E5E5E5",
                                borderTop: "1px solid #E5E5E5",
                            };
                    },
                }}>
                    <MenuItem onClick={()=>scrollTo("landing")}> Start</MenuItem>
                    <MenuItem onClick={()=>scrollTo("services")}> Services</MenuItem>
                    <MenuItem onClick={()=>scrollTo("about-us")}> About</MenuItem>
                    <MenuItem onClick={()=>scrollTo("price-list")}> Stocklist</MenuItem>
                    <MenuItem onClick={()=>scrollTo("contact-us")}> Contact</MenuItem>
                </Menu>
            </Sidebar>

        </div>
    );
}